import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import * as S from "../components/Clientes/styled"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <S.Clientes>
      <S.ClientesWrapper>
        <S.ClientesHeader>
          <S.ClientesHeaderTitle>Página não encontrada</S.ClientesHeaderTitle>
          <S.ClientesHr />
        </S.ClientesHeader>
        <S.ClientesText>
          Sentimos muito, mas não conseguimos encontrar a página que está
          buscando, por favor navegue pelo menu e caso o erro persista nos envie
          uma mensagem.
          <br />
          <br />
          <br />
        </S.ClientesText>
        <S.ClientesText>Obrigado pela preferência.</S.ClientesText>
      </S.ClientesWrapper>
    </S.Clientes>
  </Layout>
)

export default NotFoundPage

import styled from "styled-components"
import media from "styled-media-query"

export const Clientes = styled.section`
  padding-bottom: 10rem;
  padding-top: 1rem;

  ${media.lessThan(`large`)`
    padding-bottom: 2rem;
  `}
`
export const ClientesWrapper = styled.div`
  width: 80vw;
  min-width: 960px;
  margin: 0 auto;

  ${media.lessThan("large")`
    width: 100%;
    min-width: 100%;
  `}
`

export const ClientesHeader = styled.div`
  display: block;
  width: 100%;
  margin: 4rem auto 0;
  padding: 1.5rem 2rem 1.5rem 0;
`
export const ClientesHeaderTitle = styled.h1`
  font-size: 2rem;
  text-align: left;
  font-weight: 700;
  margin-bottom: 2rem;
  text-transform: uppercase;
`

export const ClientesHr = styled.hr`
  width: 3rem;
  height: 1px;
  background: var(--black);
  text-align: left;
  margin: 0;
`
export const ClientesText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 2;
  color: var(--text);
  margin-bottom: 1rem;

  ${media.lessThan(`large`)`
    padding: 1rem;
  `}
`

export const ClientesBox = styled.div`
  display: flex;
  justify-content: flex-start;

  ${media.lessThan(`large`)`
    flex-direction: column;
    padding: 1rem;
  `}
`

export const ClientesLista = styled.ul`
  display: block;
  margin: 0 10rem 0 0;

  ${media.lessThan(`large`)`
    margin: 0;

  `}
`
export const ClientesListaItem = styled.li`
  font-family: "Lato", sans-serif;
  margin: 0.75rem 0;
  list-style: disc inside;
  color: var(--text);
`
